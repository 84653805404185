/**
 * 地址中间截断
 * @param address string ETH Address
 * @param reserve number truncate 后左右两边保留的位数
 *
 * @example
 * const addr = '0x22038cB39Dc4F045aC5053Cf7F0541eA2772C650'
 * const truncatedAddr = truncateAddress(addr)
 * // 0x22038...C650
 */
export function truncateAddress(address) {
  var reserve = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 4;
  if (!address) return '';
  var left = address.substr(0, 2 + reserve);
  var right = address.substr(address.length - reserve);
  return "".concat(left, "...").concat(right);
}
export function truncateBTCAddress(address) {
  var reserve = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 6;
  if (!address) return '';
  var left = address.substr(0, reserve);
  var right = address.substr(address.length - reserve);
  return "".concat(left, "...").concat(right);
}