import BigNumber from 'bignumber.js';
import { cardinalWords, ordinalWords, tensWords, chineseNums, chineseUnits } from "../constants/numbers";
export var toBigNumber = x => {
  if (x instanceof BigNumber) return x;
  if (isNaN(Number(x))) return new BigNumber(0);

  if (typeof x === 'string') {
    if (!x) return new BigNumber(0);

    if (x.indexOf('0x') === 0 || x.indexOf('-0x') === 0) {
      return new BigNumber(x.replace('0x', ''), 16);
    }
  }

  return new BigNumber(x);
};
export var toBigNumberWithDecimal = function toBigNumberWithDecimal(x) {
  var decimal = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
  return toBigNumber(x).dividedBy(Math.pow(10, decimal));
};
export var formatNumber = function formatNumber(x) {
  var place = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
  var decimal = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;
  if (x === undefined || x === null || x === '') return '~';
  return toBigNumber(toBigNumberWithDecimal(x, decimal).toFixed(place, BigNumber.ROUND_DOWN)).toFormat();
};
export var formatPercentage = function formatPercentage(x) {
  var place = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
  var decimal = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;
  if (x === undefined || x === null || x === '') return '~';
  return "".concat(toBigNumberWithDecimal(x, decimal).multipliedBy(100).toFormat(place, 1), "%");
};
export var max = BigNumber.max;
export var min = BigNumber.min;
export var numberToOrdinal = num => {
  if (num < 0 || num > 1000) {
    return 'Number out of range';
  } else if (num < 20) {
    return ordinalWords[num];
  } else if (num < 100) {
    var onesDigit = num % 10;
    var tensDigit = Math.floor(num / 10);
    return tensWords[tensDigit] + (onesDigit ? '-' + ordinalWords[onesDigit] : '');
  } else {
    var hundredsDigit = Math.floor(num / 100);
    var remainder = num % 100;
    return cardinalWords[hundredsDigit] + ' hundred' + (remainder ? ' and ' + numberToOrdinal(remainder) : 'th');
  }
};
export var numberToOrdinalWithSuffix = num => {
  var suffix = 'th';
  var lastDigit = num % 10;
  var lastTwoDigits = num % 100;

  if (lastDigit === 1 && lastTwoDigits !== 11) {
    suffix = 'st';
  } else if (lastDigit === 2 && lastTwoDigits !== 12) {
    suffix = 'nd';
  } else if (lastDigit === 3 && lastTwoDigits !== 13) {
    suffix = 'rd';
  }

  return num.toString() + suffix;
};
export var numberToChinese = num => {
  if (num < 0 || num > 1000) {
    return 'Number out of range';
  } else if (num < 10) {
    return chineseNums[num];
  } else if (num < 100) {
    var onesDigit = num % 10;
    var tensDigit = Math.floor(num / 10);
    return (tensDigit > 1 ? chineseNums[tensDigit] : '') + chineseUnits[1] + (onesDigit ? chineseNums[onesDigit] : '');
  } else {
    var hundredsDigit = Math.floor(num / 100);
    var remainder = num % 100;
    return chineseNums[hundredsDigit] + chineseUnits[2] + (remainder && remainder < 10 ? '零' : '') + (remainder ? numberToChinese(remainder) : '');
  }
};
export var formatCountUnit = num => {
  var BILLION = 1000000000;
  var MILLION = 1000000;
  var options = {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2
  };

  if (num >= BILLION) {
    return (num / BILLION).toLocaleString('en-US', options) + 'B';
  } else if (num >= MILLION) {
    return (num / MILLION).toLocaleString('en-US', options) + 'M';
  } else {
    return num.toLocaleString('en-US', options);
  }
};
export var formatFormAmount = function formatFormAmount(number) {
  var precision = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 6;
  var bigNumber = toBigNumber(number);
  if (bigNumber.isZero()) return '0';
  if (precision === 0) return bigNumber.toFixed(0, BigNumber.ROUND_DOWN);
  var formattedNumber = bigNumber.toFixed(precision, BigNumber.ROUND_DOWN).replace(/\.?0+$/, '');

  if (formattedNumber === '0') {
    return "<".concat(new BigNumber(10).pow(-precision));
  }

  return formattedNumber;
};