import React from "react";
var __jsx = React.createElement;
import router from 'next/router';
export var RouterLink = _ref => {
  var href = _ref.href,
      className = _ref.className,
      children = _ref.children;

  var handleClick = e => {
    e.preventDefault();
    router.push(href);
  };

  return __jsx("a", {
    href: href,
    onClick: handleClick,
    className: className
  }, children);
};