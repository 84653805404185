import React from "react";
var __jsx = React.createElement;
import { StyledAccount, StyledAccountAvatar, StyledAccountInfo } from "./styled";
import { truncateAddress } from "../../utils/truncateAddress";
import Avatar01 from "../../assets/images/avatar-01.svg";
import Avatar02 from "../../assets/images/avatar-02.svg";
import Avatar03 from "../../assets/images/avatar-03.svg";
import Avatar04 from "../../assets/images/avatar-04.svg";
import Avatar05 from "../../assets/images/avatar-05.svg";
import { useWeb3React } from '@web3-react/core';
import { useNativeCurrencySymbol, useNativeTokenBalance } from "../../state/wallet/hooks";
import { useDomainName } from "../../hooks";
import { getConnection } from "../../connection/utils";
var Avatar = [Avatar01, Avatar02, Avatar03, Avatar04, Avatar05];
export var Account = () => {
  var _useWeb3React = useWeb3React(),
      account = _useWeb3React.account,
      connector = _useWeb3React.connector;

  var _useDomainName = useDomainName(),
      name = _useDomainName.name;

  var ethBalance = useNativeTokenBalance();
  var avatarIndex = parseInt(account.substr(0, 3), 16) % 5;
  var nativeSymbol = useNativeCurrencySymbol();
  var connectorInfo = getConnection(connector);
  return __jsx(StyledAccount, {
    className: "user-address"
  }, __jsx(StyledAccountAvatar, {
    className: "avatar left",
    size: 24,
    rounded: !(connectorInfo !== null && connectorInfo !== void 0 && connectorInfo.icon),
    src: (connectorInfo === null || connectorInfo === void 0 ? void 0 : connectorInfo.icon) || Avatar[avatarIndex],
    alt: "avatar"
  }), account ? __jsx(StyledAccountInfo, {
    className: "user-address__info"
  }, __jsx("span", {
    className: "address"
  }, name || truncateAddress(account)), ethBalance && __jsx("span", {
    className: "balance"
  }, ethBalance === null || ethBalance === void 0 ? void 0 : ethBalance.toSignificant(4), " ", nativeSymbol)) : null);
};