import React from "react";
var __jsx = React.createElement;
import { useRouter } from 'next/router';
import { StyledSidebarFooter, StyledFooterListArea, StyledHeaderPlaceholder } from "./styled";
export var Sidebar = _ref => {
  var opened = _ref.opened,
      handleClose = _ref.handleClose;

  var _useRouter = useRouter(),
      pathname = _useRouter.pathname;

  return __jsx(React.Fragment, null, __jsx(StyledSidebarFooter, {
    opened: opened,
    handleClose: handleClose
  }, __jsx(StyledHeaderPlaceholder, null), __jsx(StyledFooterListArea, null)));
};
export default Sidebar;