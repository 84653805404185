import React from "react";
var __jsx = React.createElement;
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import NotificationBar from "../../NotificationBar";
import { useGA } from "../../../hooks";

var HeaderNotificationBar = () => {
  var _useTranslation = useTranslation(),
      t = _useTranslation.t;

  var _useGA = useGA(),
      trackClick = _useGA.trackClick;

  var _useState = useState(true),
      isVisible = _useState[0],
      setIsVisible = _useState[1];

  var url = 'https://v5.tokenlon.im';

  var handleLinkClick = () => {
    trackClick('webtl_topbanner', {
      title: t('header_notification'),
      url
    });
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  useEffect(() => {
    if (history.scrollRestoration) {
      history.scrollRestoration = 'manual';
    }
  }, []);
  return __jsx(React.Fragment, null, isVisible && __jsx(NotificationBar, {
    text: t('header_notification'),
    linkText: t('header_notification_btn'),
    handleClose: () => setIsVisible(false),
    handleLinkClick: handleLinkClick,
    style: {
      position: 'sticky',
      top: 0,
      zIndex: 200,
      borderRadius: 0
    }
  }));
};

export default HeaderNotificationBar;