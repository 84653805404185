import React from "react";
var __jsx = React.createElement;
import { useCallback, useState, useEffect, useLayoutEffect, useRef } from 'react';
import { StyledTokenlonHeader, StyledTabsContainer, StyledMainNavItem, StyledControls, ConnectButton, StyledLogo, StyledMenuIcon, StyledHeaderMain, StyledSubNavPortal, StyledSubNavItem, StyledSubNavPortalContainer, StyledHoverRow, StyledInstantButton } from "./styled";
import { NetworkSelector } from "./components/NetworkSelector";
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';
import { useBreakpoint, useForceUpdate, useGA, useLimitOrderRouter } from "../../hooks";
import { Account } from "../Account";
import { useWeb3React } from '@web3-react/core';
import { ApplicationModal } from "../../state/application/reducer";
import { useModalToggle } from "../../state/application/hooks";
import { RouterLink } from "../RouterLink";
import { NetworkMessage } from "../NetworkMessage";
import Portal from "../Portal";
import Languages from "./components/Languages";
import { HelpScout } from "../Helpscout";
import { coinbaseWalletConnection } from "../../connection";
import HeaderBanner from "./components/HeaderBanner";
import HeaderNotificationBar from "./components/HeaderNotificationBar";

var useNavMenu = () => {
  var _useTranslation = useTranslation(),
      t = _useTranslation.t;

  return [{
    text: t('products'),
    pathname: '/instant',
    testid: 'site-nav-instant',
    trackid: 'webtl_products',
    activeKey: 'instant',
    subMenuItems: [{
      text: t('instant_swap'),
      pathname: '/instant',
      testid: 'site-nav-exchange_instant',
      trackid: 'webtl_products_instant'
    }, {
      text: t('limit_order'),
      pathname: '/limit-order',
      testid: 'site-nav-exchange_limit_order',
      trackid: 'webtl_products_lo'
    }, {
      text: t('wrap_eth'),
      pathname: '/weth',
      testid: 'site-nav-exchange_weth',
      trackid: 'webtl_products_weth'
    }]
  }, {
    text: 'LON',
    pathname: '/lon',
    testid: 'site-nav-lon',
    trackid: 'webtl_lon',
    activeKey: 'lon',
    subMenuItems: [{
      text: t('sub_nav_overview'),
      pathname: '/lon',
      testid: 'site-nav-lon_overview',
      trackid: 'webtl_lon_overview'
    }, {
      text: t('sub_nav_dashboard'),
      pathname: '/lon/dashboard',
      testid: 'site-nav-lon_dashboard',
      trackid: 'webtl_lon_dashboard'
    }, {
      text: t('sub_nav_trade_mining'),
      pathname: '/lon/mining/trade',
      testid: 'site-nav-lon_trade_mining',
      trackid: 'webtl_lon_trademining'
    }, {
      text: t('sub_nav_buyback'),
      pathname: '/lon/buyback',
      testid: 'site-nav-lon_buyback',
      trackid: 'webtl_lon_buyback'
    }, {
      text: t('sub_nav_staking'),
      pathname: '/lon/staking',
      testid: 'site-nav-lon_staking',
      trackid: 'webtl_lon_staking'
    }, {
      text: t('sub_nav_liquity_mining'),
      pathname: '/lon/mining/lp',
      testid: 'site-nav-lon_liquity_mining',
      trackid: 'webtl_lon_liquidmining'
    }]
  }, {
    text: t('bridges_menu'),
    activeKey: 'bridge',
    testid: 'site-nav-bridge',
    trackid: 'webtl_bridge',
    subMenuItems: [{
      text: 'Hop',
      pathname: 'https://bit.ly/3xiuP8L',
      testid: 'site-nav-bride_hop',
      trackid: 'webtl_bridge_hop'
    }, {
      text: 'Arbitrum',
      pathname: 'https://bridge.arbitrum.io/',
      testid: 'site-nav-bride__1',
      trackid: 'webtl_bridge_arbitrum'
    }, {
      text: 'Celer cBridge',
      pathname: 'https://bit.ly/3wIClv5',
      testid: 'site-nav-bride__2',
      trackid: 'webtl_bridge_cbridge'
    }]
  }, {
    text: t('community'),
    activeKey: 'community',
    trackid: 'webtl_community',
    testid: 'site-nav-community',
    subMenuItems: [{
      text: t('bounty'),
      pathname: 'https://forum.tokenlon.im/c/tokenlon-bounty/9',
      testid: 'site-nav-community_bounty',
      trackid: 'webtl_community_bounty'
    }, {
      text: t('forum'),
      pathname: 'https://forum.tokenlon.im',
      testid: 'site-nav-community_forum',
      trackid: 'webtl_community_forum'
    }, {
      text: t('vote'),
      pathname: 'https://snapshot.org/#/tokenlon.eth',
      testid: 'site-nav-community_vote',
      trackid: 'webtl_community_vote'
    }, {
      text: t('twitter'),
      pathname: 'https://twitter.com/tokenlon',
      testid: 'site-nav-community_twitter',
      trackid: 'webtl_community_twitter'
    }, {
      text: t('discord'),
      pathname: 'https://discord.com/invite/ZFf4Cye',
      testid: 'site-nav-community_discord',
      trackid: 'webtl_community_discord'
    }]
  }, {
    text: 'Blog',
    activeKey: 'blog',
    pathname: '/blog',
    testid: 'site-nav-blog',
    trackid: 'webtl_blog'
  }];
};

var NavMenu = () => {
  var menuConfigs = useNavMenu();

  var _useBreakpoint = useBreakpoint(),
      isBreakPointLG = _useBreakpoint.isBreakPointLG;

  var _useRouter = useRouter(),
      pathname = _useRouter.pathname,
      asPath = _useRouter.asPath;

  var rawActive = (asPath || pathname).split('/')[1];
  var defaultActive = menuConfigs[0].subMenuItems.map(item => pathname.substring(1)).includes(rawActive) ? menuConfigs[0].activeKey : rawActive; // 初始值为空是因为第一次访问不需要展示子导航栏

  var _useState = useState(''),
      active = _useState[0],
      setActive = _useState[1];

  if (isBreakPointLG) return null;
  return __jsx(StyledTabsContainer, {
    "data-testid": "header-nav-menu"
  }, menuConfigs.map(config => __jsx(NavMenuItem, {
    key: config.testid,
    menu: config,
    active: active === config.activeKey,
    defaultActiveKey: defaultActive,
    setActive: setActive
  })));
};

var NavMenuItem = _ref => {
  var menu = _ref.menu,
      sub = _ref.sub,
      setActive = _ref.setActive,
      active = _ref.active,
      defaultActiveKey = _ref.defaultActiveKey;
  var pathname = menu.pathname,
      testid = menu.testid,
      trackid = menu.trackid,
      text = menu.text,
      subMenuItems = menu.subMenuItems,
      activeKey = menu.activeKey;

  var _useGA = useGA(),
      trackElClick = _useGA.trackElClick;

  var subNavRef = useRef();
  var forceUpdate = useForceUpdate();
  var Comp = sub ? StyledSubNavItem : StyledMainNavItem;
  useLayoutEffect(() => {
    subNavRef.current = document.getElementById('site-sub-nav');
  }, []);
  useEffect(() => {
    forceUpdate();
  }, [subNavRef.current]);
  return __jsx(React.Fragment, null, __jsx(Comp, {
    pathname: pathname,
    sub: sub,
    "data-testid": testid,
    "data-trackid": trackid,
    onClick: trackElClick,
    navText: text,
    active: active,
    onMouseOver: () => {
      setActive(subMenuItems ? activeKey : '');
    }
  }), subMenuItems && subNavRef.current && active ? __jsx(SubMenuPortalItem, {
    portalRef: subNavRef,
    menus: subMenuItems,
    parentActiveKey: activeKey,
    defaultActiveKey: defaultActiveKey
  }) : null);
};

var SubMenuPortalItem = _ref2 => {
  var menus = _ref2.menus,
      portalRef = _ref2.portalRef;

  var _useRouter2 = useRouter(),
      currentPathname = _useRouter2.pathname,
      asPath = _useRouter2.asPath,
      route = _useRouter2.route; // const displayThemeColor = route === '/'


  return __jsx(Portal, {
    direct: true,
    containerRef: portalRef
  }, __jsx(StyledHoverRow, {
    displayThemeColor: false
  }, __jsx(StyledSubNavPortal, null, menus.map(item => __jsx(NavMenuItem, {
    sub: true,
    key: item.testid,
    menu: item,
    active: [currentPathname, asPath].includes(item.pathname)
  })))));
};

var useWalletConnectAction = () => {
  var _useGA2 = useGA(),
      trackClick = _useGA2.trackClick;

  var toggleWalletModal = useModalToggle(ApplicationModal.WALLET_CONNECT, {
    stack: true
  });
  return useCallback(() => {
    var _window$ethereum;

    if (typeof window !== 'undefined' && (_window$ethereum = window.ethereum) !== null && _window$ethereum !== void 0 && _window$ethereum.isCoinbaseWallet) {
      return coinbaseWalletConnection.connector.activate();
    }

    trackClick('webtl_walletconnect', {
      from: 'navigation'
    });
    toggleWalletModal();
  }, [toggleWalletModal, trackClick]);
};

var HeaderControls = () => {
  var _useRouter3 = useRouter(),
      route = _useRouter3.route,
      asPath = _useRouter3.asPath;

  var isLimitOrder = useLimitOrderRouter();
  var isInstantRouter = asPath === '/instant' || /^\/exchange/g.test(route);
  var isLonRouter = /^\/lon/g.test(route);
  var isWETHRouter = /^\/weth/g.test(route);
  var showAccountControls = isLimitOrder || isInstantRouter || isLonRouter || isWETHRouter;
  return __jsx(StyledControls, {
    "data-testid": "header-controls"
  }, showAccountControls ? __jsx(AccountControls, null) : __jsx(LandingControls, null));
};

var AccountControls = () => {
  var _useWeb3React = useWeb3React(),
      account = _useWeb3React.account;

  var _useTranslation2 = useTranslation(),
      t = _useTranslation2.t;

  var connect = useWalletConnectAction();

  var _useGA3 = useGA(),
      trackElClickCurring = _useGA3.trackElClickCurring;

  var toggleInfoModal = useModalToggle(ApplicationModal.WALLET_INFO, {
    stack: true
  });
  return __jsx(React.Fragment, null, __jsx(NetworkSelector, null), account ? __jsx("div", {
    "data-trackid": "webtl_mywallet",
    onClick: e => trackElClickCurring(e)(toggleInfoModal)
  }, __jsx(Account, null)) : __jsx(ConnectButton, {
    onClick: connect
  }, t('connect')));
};

var LandingControls = () => {
  var _useTranslation3 = useTranslation(),
      t = _useTranslation3.t;

  var _useGA4 = useGA(),
      trackElClick = _useGA4.trackElClick;

  return __jsx(React.Fragment, null, __jsx(Languages, null), __jsx(StyledInstantButton, {
    href: "/instant",
    "data-trackid": "webtl_startinstant",
    "data-trackdata": JSON.stringify({
      from: 'hp_banner'
    }),
    onClick: trackElClick
  }, t('launch_dapp')));
};

var TokenlonHeader = () => {
  // const { route } = useRouter()
  // const displayThemeColor = route === '/'
  return __jsx(React.Fragment, null, __jsx(HeaderNotificationBar, null), __jsx(HeaderMessage, null), __jsx(HeaderBanner, null), __jsx(StyledTokenlonHeader, {
    "data-testid": "tokenlon-header",
    displayThemeColor: false
  }, __jsx(HeaderMain, null)), __jsx(SubNavPortal, null));
};

export var HeaderMain = () => {
  var _useGA5 = useGA(),
      trackElClick = _useGA5.trackElClick;

  var toggleSidebar = useModalToggle(ApplicationModal.MOBILE_SIDEBAR, {
    stack: true
  });
  return __jsx(StyledHeaderMain, {
    "data-testid": "site-header-main"
  }, __jsx(RouterLink, {
    href: "/",
    className: "logo-link"
  }, __jsx(StyledLogo, {
    "data-trackid": "webtl_tlpic",
    onClick: trackElClick,
    "data-testid": "header-brand-logo"
  })), __jsx(HelpScout, null), __jsx(NavMenu, null), __jsx(HeaderControls, null), __jsx(StyledMenuIcon, {
    onClick: toggleSidebar
  }));
};
export var SubNavPortal = () => {
  return __jsx(StyledSubNavPortalContainer, {
    "data-testid": "site-sub-nav",
    id: "site-sub-nav"
  });
};
export var HeaderMessage = () => {
  return __jsx("div", {
    "data-testid": "header-message"
  }, __jsx(NetworkMessage, null));
};
export default TokenlonHeader;