import _taggedTemplateLiteral from "/vercel/path0/node_modules/.pnpm/next@12.2.0_@babel+core@7.16.0_react-dom@17.0.2_react@17.0.2/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";

var _templateObject;

import React from "react";
var __jsx = React.createElement;
import { useRouter } from 'next/router';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { useTranslation } from 'react-i18next';
import { useBreakpoint } from "../../../hooks";
import { LOCALES } from "../../../locales";
import styled from 'styled-components';
import PCBannerZH from "../../../assets/images/header-banner-campaign-pc-zh.png";
import PCBannerEN from "../../../assets/images/header-banner-campaign-pc-en.png";
import MobileBannerZH from "../../../assets/images/header-banner-campaign-mobile-zh.png";
import MobileBannerEN from "../../../assets/images/header-banner-campaign-mobile-en.png";
dayjs.extend(utc);
dayjs.extend(timezone);

var HeaderBanner = () => {
  var _useTranslation = useTranslation(),
      i18n = _useTranslation.i18n;

  var _useBreakpoint = useBreakpoint(),
      isMobile = _useBreakpoint.isMobile;

  var now = dayjs();
  var startTime = dayjs.tz('2024-08-08 12:00:00', 'Asia/Shanghai');
  var endTime = dayjs.tz('2024-08-26 12:00:00', 'Asia/Shanghai');
  if (now.isBefore(startTime) || now.isAfter(endTime)) return null;
  var router = useRouter();
  if (router.pathname === '/campaign') return null;
  var isZh = i18n.resolvedLanguage === LOCALES.ZH; // const lang = isZh ? 'zh-cn' : 'en-us'

  var PCBanner = isZh ? PCBannerZH : PCBannerEN;
  var MobileBanner = isZh ? MobileBannerZH : MobileBannerEN;
  var Banner = isMobile ? MobileBanner : PCBanner; // const url = `https://support.tokenlon.im/hc/${lang}/articles/14142796564884`

  var url = '/campaign';

  var handleClick = () => {
    if (url.startsWith('/')) {
      router.push(url);
    } else window.open(url, '_blank', 'noopener,noreferrer');
  };

  return __jsx(StyledBannerWrapper, {
    onClick: handleClick
  }, __jsx(StyledBanner, {
    src: Banner.src,
    alt: "banner"
  }));
};

export default HeaderBanner;
var StyledBannerWrapper = styled.div.withConfig({
  displayName: "StyledBannerWrapper",
  componentId: "sc-l5iqf-0"
})(["display:flex;justify-content:center;align-items:center;background:#f9eeff;cursor:pointer;"]);
var StyledBanner = styled.img.withConfig({
  displayName: "StyledBanner",
  componentId: "sc-l5iqf-1"
})(["width:880px;", ""], _ref => {
  var theme = _ref.theme;
  return theme.media.sm(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n    width: 100%;\n  "])));
});