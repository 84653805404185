import _taggedTemplateLiteral from "/vercel/path0/node_modules/.pnpm/next@12.2.0_@babel+core@7.16.0_react-dom@17.0.2_react@17.0.2/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";

var _templateObject;

import styled from 'styled-components';
var StyledFooterListArea = styled.div.withConfig({
  displayName: "StyledFooterListArea",
  componentId: "sc-n1dhas-0"
})(["display:flex;flex:1;align-items:flex-start;justify-content:space-between;.footer-list{flex:1;&:last-child{flex:0;}}h3{font-weight:500;font-size:14px;margin-bottom:16px;color:#5a5d6d;}ul{list-style:none;margin:0;padding:0;color:#8b8799;}li{margin-bottom:14px;}a{color:#8b8799;font-size:14px;&:hover{color:#5f4dbc;}}", ""], _ref => {
  var theme = _ref.theme;
  return theme.media.lg(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n    .list-divider {\n      border-color: #eaecf6;\n      border-top: none;\n      margin-bottom: 10px;\n      margin-top: 45px;\n    }\n  "])));
});
export { StyledFooterListArea };