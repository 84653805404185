import { useWeb3React } from '@web3-react/core';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { setupBeacon } from "../utils/helpscout";
export var useHelpScout = () => {
  var _useTranslation = useTranslation(),
      i18n = _useTranslation.i18n;

  var _useWeb3React = useWeb3React(),
      account = _useWeb3React.account;

  useEffect(() => {
    setupBeacon(i18n.resolvedLanguage);
  }, [i18n.resolvedLanguage]);
  return useCallback(function () {
    var visible = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;

    if (!window || !window.Beacon) {
      console.warn('helpScout init failed');
      return;
    }

    if (visible) {
      window.Beacon('open');
      var imToken = window.imToken || {
        agent: '',
        deviceToken: ''
      };
      window.Beacon('session-data', {
        address: account,
        agent: imToken.agent
      });
    } else {
      window.Beacon('close');
    }
  }, [account]);
};