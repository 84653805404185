import styled from 'styled-components';
var StyledSidebarWrapper = styled.div.withConfig({
  displayName: "StyledSidebarWrapper",
  componentId: "sc-1wqyqss-0"
})(["&.opened{.sidebar-mask{display:block;}.sidebar{transform:translateX(0);}}@media screen and (max-width:640px){&.opend{width:100%;}}"]);
var StyledSidebarMask = styled.div.withConfig({
  displayName: "StyledSidebarMask",
  componentId: "sc-1wqyqss-1"
})(["width:100%;height:100%;position:fixed;top:0;left:0;background:rgba(29,36,71,0.5);display:none;z-index:101;"]);
var StyledSidebar = styled.div.withConfig({
  displayName: "StyledSidebar",
  componentId: "sc-1wqyqss-2"
})(["background:#fff;box-shadow:0px 2px 4px rgba(221,221,221,0.5);position:fixed;width:400px;height:100%;top:0;right:0;transform:translateX(80vw);transition:transform 0.3s;z-index:111;overflow:hidden;display:flex;flex-direction:column;.close-icon{position:absolute;width:20px;height:20px;top:27px;left:10px;cursor:pointer;}@media (max-width:640px){width:80%;.close-icon{position:absolute;top:27px;right:10px;}}"]);
export { StyledSidebarWrapper, StyledSidebarMask, StyledSidebar };