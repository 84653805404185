import _slicedToArray from "/vercel/path0/node_modules/.pnpm/next@12.2.0_@babel+core@7.16.0_react-dom@17.0.2_react@17.0.2/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";
var __jsx = React.createElement;
import router from 'next/router';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocalStorage } from "../../../hooks/useLocalStorage";
import { LOCALES } from "../../../locales";
import { useModalToggle } from "../../../state/application/hooks";
import { ApplicationModal } from "../../../state/application/reducer";

var MobileLinkItem = _ref => {
  var _ref$url = _ref.url,
      url = _ref$url === void 0 ? '' : _ref$url,
      name = _ref.name,
      onClick = _ref.onClick;

  var _useTranslation = useTranslation(),
      i18n = _useTranslation.i18n;

  var _useLocalStorage = useLocalStorage('lang', LOCALES.EN),
      _useLocalStorage2 = _slicedToArray(_useLocalStorage, 2),
      _ = _useLocalStorage2[0],
      setValue = _useLocalStorage2[1];

  var toggleSidebar = useModalToggle(ApplicationModal.MOBILE_SIDEBAR, {
    stack: true
  });
  var isLanguage = useMemo(() => url === LOCALES.ZH || url === LOCALES.EN, [url]);
  var target = useMemo(() => "".concat(url).startsWith('/') ? '' : '_blank', [url]);
  var safeURL = useMemo(() => {
    if (isLanguage || !url) return '#';
    return url;
  }, [isLanguage, url]);

  var handleLanguageChange = lang => {
    setValue(lang);
    i18n.changeLanguage(lang);
  };

  var clickHandler = event => {
    onClick === null || onClick === void 0 ? void 0 : onClick(); // resolve spa-router

    if (url.startsWith('/')) {
      event.preventDefault();
      console.log('router', url);
      router.push(url);
      toggleSidebar();
      return false;
    } // resolve external links


    if (!isLanguage) return; // resolve language change

    event.preventDefault();
    console.log('lang', url);
    var lang = url;
    handleLanguageChange(lang);
  };

  return __jsx("li", null, __jsx("a", {
    target: target,
    href: safeURL,
    onClick: clickHandler
  }, name));
};

export default MobileLinkItem;