import React from "react";
var __jsx = React.createElement;
import { useTranslation } from 'react-i18next';
import { StyledBar, StyledTitleWrapper, StyledIcon, StyledCloseIcon, StyledLink, StyledWarningCheck } from "./styled";
import IconInfo from "../../assets/images/exclamation-mark-blue.svg";
import IconWarning from "../../assets/images/warning-alert.svg";
import IconRisk from "../../assets/images/risk-alert.svg";
import IconClose from "../../assets/images/modal-close.svg";

var NotificationBar = _ref => {
  var text = _ref.text,
      _ref$level = _ref.level,
      level = _ref$level === void 0 ? 'info' : _ref$level,
      linkText = _ref.linkText,
      _ref$showIcon = _ref.showIcon,
      showIcon = _ref$showIcon === void 0 ? true : _ref$showIcon,
      _ref$showLink = _ref.showLink,
      showLink = _ref$showLink === void 0 ? true : _ref$showLink,
      _ref$showClose = _ref.showClose,
      showClose = _ref$showClose === void 0 ? true : _ref$showClose,
      _ref$showCheckBox = _ref.showCheckBox,
      showCheckBox = _ref$showCheckBox === void 0 ? false : _ref$showCheckBox,
      handleClose = _ref.handleClose,
      handleLinkClick = _ref.handleLinkClick,
      style = _ref.style,
      checked = _ref.checked,
      setChecked = _ref.setChecked;

  var _useTranslation = useTranslation(),
      t = _useTranslation.t;

  var levelIcons = {
    info: IconInfo,
    warning: IconWarning,
    risk: IconRisk,
    verified: IconInfo
  };
  return __jsx(StyledBar, {
    level: level,
    style: style
  }, __jsx(StyledTitleWrapper, null, showIcon && __jsx(StyledIcon, {
    src: levelIcons[level]
  }), showCheckBox && __jsx(StyledWarningCheck, {
    htmlFor: "notification-checkbox",
    onClick: () => {
      setChecked === null || setChecked === void 0 ? void 0 : setChecked(!checked);
    },
    level: level
  }, __jsx("input", {
    type: "checkbox",
    name: "notification-checkbox",
    checked: checked,
    readOnly: true
  }), __jsx("span", {
    className: "checkmark"
  })), __jsx("div", null, __jsx("span", null, text), "\xA0", showLink && __jsx(StyledLink, {
    onClick: handleLinkClick
  }, linkText !== null && linkText !== void 0 ? linkText : "".concat(t('learn_more'), " >")))), showClose && __jsx(StyledCloseIcon, {
    src: IconClose,
    onClick: handleClose
  }));
};

export default NotificationBar;